<template>
    <div style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>学员反馈</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="messages">
            <div class="clearfix messages-search">
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-select style="width:200px" allowClear showSearch v-model="searchParams.search.studio_id" placeholder="请选择所属校区" :filterOption="filterOption">
                                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>时间筛选</span>
                            </template>
                            <a-range-picker
                                style="width: 240px"
                                :ranges="rangesData"
                                :value='rangesValue'
                                @change="(date, dateString)=>onChange(date, dateString,2)" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="searchList">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
                class="messages-list"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <a-card class="messages-card">
                            <div class="messages-card-nav">
                                <a-avatar :src="item.commnet_avatar" :size="35" icon="user" />
                                <div class="messages-card-name">
                                    <div>{{item.commnet_name}}</div>
                                    <div style="font-size: 12px;">{{item.comment_at}}</div>
                                    <a-rate v-if="item.commnet_rate"  :default-value="item.commnet_rate" allow-half disabled />
                                </div>
                                
                            </div>
                            <div class="messages-card-mar messages-card-text">
                                {{item.commnet_content}}
                            </div>
                            <div class="messages-card-mar messages-card-img">
                                <img :preview="index" v-for="(it,ind) in item.comment_image" :key="ind" :src="it">
                            </div>
                            <div class="messages-card-mar messages-card-tag">
                                <a-tag>{{item.comment_to.studio_name}}</a-tag>
                                <!-- <a-tag>{{item.comment_to.room_name}}</a-tag> -->
                                <a-tag>{{item.comment_to.teacher_name}}</a-tag>
                                <a-tag>{{item.comment_to.class_date}}</a-tag>
                            </div>
                            <template slot="actions" class="ant-card-actions">
                                <!-- <div><a-icon key="eye" type="eye" /><span style="margin-left:5px">{{item.view_count}}</span></div> -->
                                <!-- <div>
                                    <a-icon key="message" type="message" />
                                    <span style="margin-left:5px">{{item.comment_count}}</span>
                                </div> -->
                                <div><a-icon key="like" type="like" /><span style="margin-left:5px">{{item.commnet_like_count}}</span></div>
                            </template>
                        </a-card>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
        </div>
        <videoModal v-if="isVideoModal" :video='video'/>
    </div>
</template>

<script>
    import moment from 'moment'
    import url from '@/utils/URL'
    import { delay } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    import videoModal from '../review/videoModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'messages',
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                busy: true,
                visible: false,
                zlVisible: false,
                loading: false,
                stuLoading: false,
                upLoadLoading: false,
                audioLoading: false,
                list:[],
                studios:[],
                video:'',
                isVideoModal:false,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                fileList: [],
                fileList2: [],
                fileList3: [],
                zlList:[],
                messageKnowledge:undefined,
                student_id:'',
                student_name:'',
                mentionsValue:'',
                students:[],
                allTextArea:'',
                comment_entity:'',
                commnet_index:'',
                commnet_name:'',
                commnet_id:'',
            }
        },
        components: {
            videoModal,
        },
        directives: { infiniteScroll },
        mixins:[ranges],
        created () {
            this.rangesValue = []
            this.getList()
            this.getStudio()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('scheduleCommentAction',this.searchParams)
                if(this.searchParams.page == 1){
                    this.busy = false
                }
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }
                if(res._meta.totalCount == this.list.length){
                    this.busy = true
                }
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchStudioAction', {})
                this.studios = res.data
            },
            async handleSearch(obj) {
                let res = await this.$store.dispatch('searchStudentsAction', { params:obj })
                this.students = res.data
                this.stuLoading = false
                // this.$forceUpdate()
            },
            report(e){
                var num=0
                e.forEach(function (item, index){
                    num += Number(item.star*2)
                })
                var data = parseInt(num/e.length)/2
                if (data.toString().indexOf('.')==-1){
                    data=data.toString()+'.0'
                }
                return data
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            toReply(item,it,index){
                this.comment_entity = item.comment_entity
                this.commnet_id = it.commnet_id
                this.commnet_index = index
                this.commnet_name = it.commnet_name
                this.allTextArea = `回复:${it.commnet_name} `
            },
            async addComment(){
                if(!this.allTextArea){
                    this.$message.warn('请输入评论!!')
                    return false
                }
                let answer = ''
                if(this.allTextArea.indexOf(`回复:${this.commnet_name}`) != -1){
                    if(this.allTextArea.indexOf(`回复:${this.commnet_name} `) != -1){
                        answer = this.allTextArea.split(`回复:${this.commnet_name} `)[1]
                    }else{
                        answer = this.allTextArea.split(`回复:${this.commnet_name}`)[1]
                    }
                }else{
                    answer = this.allTextArea
                }
                let obj = {
                    comment_entity:this.comment_entity,
                    parentId:this.commnet_id,
                    comment_content:answer
                }
                await this.$store.dispatch('scheduleAddCommentAction', obj)
                .then(res=>{
                    this.allTextArea = ''
                    this.commnet_id = ''
                    this.list[this.commnet_index].comment_count++
                    this.list[this.commnet_index].comments = res.data.message_comments
                    this.$message.success('操作成功~')
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            onSearch(search){
                this.stuLoading = !!search
                this.students = []
                let obj = {
                    q: search,
                }
                delay(() => {
                    this.handleSearch(obj)
                }, 500)
            },
            onSelect(option) {
                console.log('select', option)
                let strArr = this.mentionsValue.split('+=')
                this.mentionsValue= strArr[0] + strArr[2]
                this.student_id = strArr[1]
                this.student_name = strArr[0]
            },
            handleStudent(item){
                if(item.message_to.to === 'student'){
                    this.students = [{
                        student_name:item.message_to.to_name,
                        student_id:item.message_to.to_id,
                        student_avatar:item.message_to.to_avatar,
                        student_cellphone:''
                    }]
                    this.busy = true
                    this.searchParams.search.student_id = item.message_to.to_id
                    this.searchParams.page = 1
                    this.list = []
                    this.getList()
                }
            },
            searchList(){
                this.busy = true
                this.searchParams.page = 1
                this.list = []
                this.getList()
            },
            closeSearch() {
                this.searchVisible = false
            },
            reset() {
                this.searchParams.search = {is_sub:this.searchParams.search.is_sub}
            },
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        &-list{
            height: calc(100vh - 205px);
            overflow-y: auto;
        }
        &-search{
            width: 700px;
            margin: 0 auto;
        }
        &-send{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-audio{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                &-icon{
                    margin-left: 10px;
                    font-size: 24px;
                    cursor: pointer;
                }
                &-loading{
                    width: 300px;
                    height: 54px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        &-card{
            width: 700px;
            margin: 0 auto;
            &-nav{
                display: flex;
            }
            &-name{
                margin-left: 20px;
            }
            &-mar{
                margin-left: 55px;
                margin-top: 10px;
            }
            &-text{
                white-space: normal;
                word-break: break-all;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-rate{
                display: flex;
                align-items: center;
            }
            &-rateImg{
                width: 100px;
                margin-right: 50px;
            }
            &-rateBox{
                display: flex;
                align-items: center;
            }
            &-rateName{
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis
            }
        }
        &-comments{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 12px 24px;
            textarea{
                resize: none;
                border:1px solid #fff;
            }
            &-reply{
                border: 1px solid #e8e8e8;
                padding: 5px;
                border-radius: 2px;
                margin-bottom: 5px;
            }
            &-btn{
                text-align: right;
                margin-top: 5px;
            }
        }
        .ant-list-item{
            border-bottom: none;
            display: block;
        }
    }
</style>
<style lang="scss">
    .messages{
        .ant-comment-inner{
            padding-top: 5px;
            padding-bottom: 0px;
            p{
                margin-bottom:0px;
                padding-bottom: 5px;
            }
        }
        .messages-comments-border{
            p{
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .ant-comment-actions{
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .messages-card-tag{
        .ant-tag{
            cursor: pointer;
            margin-bottom: 5px;
            color:#999 !important;
            border-color:#f5f7fa !important;
            border-bottom-left-radius:11px;
            border-top-left-radius:11px;
            border-top-right-radius:11px;
            border-bottom-right-radius:11px;
        }
    }
</style>